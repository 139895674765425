import { useTranslation } from 'react-i18next';
import { StyledPayoutListHeader } from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { TSortPayoutByHeaderTitle } from 'types';
interface IPayoutListHeaderProps {
  children?: React.ReactNode;
  onSort: (sortBy: TSortPayoutByHeaderTitle) => void;
  sortIncr: boolean;
}
export const PayoutListHeader = ({ onSort }: IPayoutListHeaderProps) => {
  const { t } = useTranslation();
  return (
    <StyledPayoutListHeader>
      <div className="payouts-list-header-element id">
        <div>{t('payoutPage.payoutsList.header.id')}</div>
        <button className="sort-by-button" onClick={() => onSort('id')}>
          <ArrowDownIcon />
        </button>
      </div>
      <div className="payouts-list-header-element">
        <div>{t('payoutPage.payoutsList.header.dateTime')}</div>
        <button className="sort-by-button" onClick={() => onSort('date')}>
          <ArrowDownIcon />
        </button>
      </div>
      <div className="payouts-list-header-element txid">
        <div>{t('payoutPage.payoutsList.header.idTransaction')} (BTC)</div>
        <button className="sort-by-button" onClick={() => onSort('transactionId')}>
          <ArrowDownIcon />
        </button>
      </div>
      {/* <div className="payouts-list-header-element amount">
        <div>{t('payoutPage.payoutsList.header.amount')} (BTC)</div>
        <button className="sort-by-button" onClick={() => onSort('amount')}>
          <ArrowDownIcon />
        </button>
      </div> */}
      <div className="payouts-list-header-element confirmation">
        <div>{t('payoutPage.payoutsList.header.networkConfirmation')}</div>
        <button className="sort-by-button" onClick={() => onSort('confirmed')}>
          <ArrowDownIcon />
        </button>
      </div>
    </StyledPayoutListHeader>
  );
};
