import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserRepository } from 'repositories/UserRepository';
import { IAuthState } from 'store/slices/authSlice';
import { IUserAccrualState } from 'store/slices/userAccrualSlice';
import { IAccrual, TAccrualsQuery } from 'types/accruals';

export const fetchAccrualsList = createAsyncThunk<
  { accruals: IAccrual[]; count: number; size: number },
  void,
  { state: { auth: IAuthState; userAccruals: IUserAccrualState } }
>('userAccruals/fetchAccrualsList', async (_, { getState }) => {
  const {
    auth: { token },
    userAccruals: { currentPage, itemsPerPage },
  } = getState();

  if (!token) throw Error('no token');

  const params: TAccrualsQuery = { page: String(currentPage - 1), size: String(itemsPerPage) };
  const accrualsListResponse = await UserRepository.getAccruals(token, params);

  if (accrualsListResponse.status === 'failed') throw Error(accrualsListResponse.error);

  return {
    accruals: accrualsListResponse.data.result,
    count: accrualsListResponse.data.count,
    size: accrualsListResponse.data.size,
  };
});
