import { IApiResponse } from './api';

export enum EPayoutStatus {
  CONFIRMED = 'CONFIRMED',
  UNCONFIRMED = 'UNCONFIRMED',
}

export type TPayoutsFilterStatus = `${EPayoutStatus}`;

export interface IPayout {
  id: string;
  date: number;
  transactionId: string;
  amount?: string;
  confirmed: boolean;
}

export type TSortPayoutByHeaderTitle = keyof IPayout;

export type TPayoutQuery = { page: string; size: string; sort?: string; status?: EPayoutStatus };

export interface IPayoutsResponse extends IApiResponse {
  data: { result: IPayout[]; page: number; size: number; count: number };
}
