import { TSortChargeByHeaderTitle } from 'types';
import { StyledChargeListHeader } from './styles';
import { ReactComponent as ArrowDownIcon } from 'assets/icons/arrow-down-full.svg';
import { useTranslation } from 'react-i18next';
interface IChargeListHeaderProps {
  children?: React.ReactNode;
  onSort: (sortBy: TSortChargeByHeaderTitle) => void;
  sortIncr: boolean;
}
export const ChargeListHeader = ({ onSort }: IChargeListHeaderProps) => {
  const { t } = useTranslation();

  return (
    <StyledChargeListHeader>
      <div className="charges-list-header-element date-time">
        <div>{t('profitPage.chargesList.header.dateTime')}</div>
        <button className="sort-by-button" onClick={() => onSort('date')}>
          <ArrowDownIcon />
        </button>
      </div>
      <div className="charges-list-header-element hashrate">
        <div>{t('profitPage.chargesList.header.hashRate')}</div>
        <button className="sort-by-button" onClick={() => onSort('hashRate')}>
          <ArrowDownIcon />
        </button>
      </div>
      <div className="charges-list-header-element general-accruals">
        <div>{t('profitPage.chargesList.header.amount')}</div>
        <button className="sort-by-button" onClick={() => onSort('generalAccural')}>
          <ArrowDownIcon />
        </button>
      </div>
      <div className="charges-list-header-element">
        <div>{t('profitPage.chargesList.header.rate')}</div>
        <button className="sort-by-button" onClick={() => onSort('chargesPerUnit')}>
          <ArrowDownIcon />
        </button>
      </div>
    </StyledChargeListHeader>
  );
};
