import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { fetchAccrualsList } from 'store/thunks/userAccrualThunks';
import { IAccrual } from 'types/accruals';
import { ESliceDataFetchingStatus } from 'types/common';

export interface IUserAccrualState {
  accruals: IAccrual[];
  status: ESliceDataFetchingStatus;
  pagesQuantity: number;
  itemsPerPage: number;
  currentPage: number;
  errorMessage: string | null;
}

const initialState: IUserAccrualState = {
  accruals: [],
  status: ESliceDataFetchingStatus.initial,
  pagesQuantity: 1,
  itemsPerPage: 10,
  currentPage: 1,
  errorMessage: null,
};

export const userAccrualsSlice = createSlice({
  name: 'userAccruals',
  initialState,
  reducers: {
    accrualsListSetPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setItemsPerAccrualsListPage: (state, action: PayloadAction<number>) => {
      state.itemsPerPage = action.payload;
      state.currentPage = initialState.currentPage;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(fetchAccrualsList.fulfilled, (state, { payload }) => {
        state.status = ESliceDataFetchingStatus.success;
        state.accruals = payload.accruals;
        state.pagesQuantity = Math.ceil(payload.count / payload.size);
      })
      .addCase(fetchAccrualsList.pending, (state) => {
        state.status = ESliceDataFetchingStatus.loading;
        state.errorMessage = null;
      })
      .addCase(fetchAccrualsList.rejected, (state, action) => {
        state.status = ESliceDataFetchingStatus.error;
        state.errorMessage = action.error.message || 'unknown fetch accruals list error';
      }),
});

export const selectAccruals = (state: RootState) => state.userAccruals.accruals;

export const userAccrualsReducer = userAccrualsSlice.reducer;
