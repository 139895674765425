import { ICharge } from 'types';
import { StyledChargeListElement } from './styles';
import { formateDate, formateTime } from 'utils';
import { IAccrual } from 'types/accruals';
interface IChargeListElementProps extends IAccrual {
  children?: React.ReactNode;
}
export const ChargeListElement = ({ date, hashRate, amount, rate }: IChargeListElementProps) => {
  const elementDate = new Date(date);
  const formatedDate = formateDate(elementDate);
  const formatedTime = formateTime(elementDate);

  return (
    <StyledChargeListElement>
      <div className="charges-list-element-col date-time">
        {formatedDate}
        {<span className="time">{`-${formatedTime}`}</span>}
      </div>
      <div className="charges-list-element-col hashrate">{hashRate}</div>
      <div className="charges-list-element-col general-accruals">{amount}</div>
      <div className="charges-list-element-col ">{rate.toFixed(10)}</div>
    </StyledChargeListElement>
  );
};
