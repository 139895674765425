import { StyledFilterRadioElement } from './styles';
import { ReactComponent as RadioIcon } from 'assets/icons/radio-icon.svg';
import cn from 'classnames';
import { useId } from 'react';
interface IFilterRadioElementProps extends React.InputHTMLAttributes<HTMLInputElement> {
  quantity: number;
  label?: string;
}
export const FilterRadioElement = ({
  checked,
  onChange,
  value,
  quantity,
  label,
}: IFilterRadioElementProps) => {
  const radioInputId = useId();
  return (
    <StyledFilterRadioElement>
      <input
        type="radio"
        id={radioInputId}
        value={value}
        checked={checked}
        className="filter-radio-element-input"
        onChange={onChange}
      />

      <label
        htmlFor={radioInputId}
        className={cn('filter-radio-element-label', checked && 'checked')}
      >
        <RadioIcon className={cn('radio-icon', checked && 'checked')} />
        {label}
        {quantity !== null && <span className="sup">{quantity}</span>}
      </label>
    </StyledFilterRadioElement>
  );
};
